<template>
  <div class="container">
    <div class="content" style="text-align: center; padding: 80px 0 40px 0">
      <h1>Kundecaser</h1>
      <h4>Dette er vi stolte av!</h4>
    </div>
    <div class="row">
      <div v-for="(element, id) in $store.state.work" :key="id" class="col-xs-6 col-sm-4 col-lg-3 portfolio-element">
        <router-link :to="element.id.split('?p=').pop()">
          <img v-if="element.image" :src="element.image" :alt="element.title" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  components: {}
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets/images";
.portfolio-element {
  padding: 0;
}
</style>
